<template>
  <ion-page :fullscreen="true">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="closeModal" size="large" slot="icon-only" class="no-ripple">
            <ion-icon :icon="arrowBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div id="container" slot="fixed">
        <div class="scanner">
          <qrcode-stream class="qr-wrapper" :camera="camera" @init="onInit" @decode="onDecode">
            <div v-if="validationPending" class="validation-pending">
              <ion-grid>
                <ion-row class="ion-align-items-center">
                  <ion-col size="4">
                    <ion-spinner color="warning" name="crescent"></ion-spinner>
                  </ion-col>
                  <ion-col class="ion-text-start" size="8">
                    <div>
                      Loading...
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </qrcode-stream>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
  import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonSpinner,
    IonGrid,
    IonRow,
    IonCol,
    modalController,
  } from '@ionic/vue'
  import { QrcodeStream } from 'vue3-qrcode-reader'
  import { arrowBackOutline } from 'ionicons/icons'
  export default {
    name: 'QRreader',
    props: {
      title: { type: String, default: 'tap4Menu QR Reader' },
    },
    data() {
      return {
        arrowBackOutline,
        loading: false,
        destroyed: false,
        isValid: undefined,
        result: null,
        camera: 'auto',
      }
    },
    components: {
      IonPage,
      IonContent,
      IonHeader,
      IonTitle,
      IonToolbar,
      IonButtons,
      IonIcon,
      IonButton,
      IonSpinner,
      IonGrid,
      IonRow,
      IonCol,
      QrcodeStream,
    },
    methods: {
      dismissModal(status) {
        modalController.dismiss(status)
      },
      closeModal() {
        let status = {
          failed: false,
        }
        return this.dismissModal(status)
      },
      onInit(promise) {
        this.loading = true
        promise.catch(console.error).then(this.resetValidationState)
      },

      resetValidationState() {
        this.isValid = undefined
      },

      async onDecode(content) {
        this.result = content

        this.turnCameraOff()
        const urlSearch = /^(https:\/\/tap4menu.app\?h=)(\d+)+(\+)+(\d+)/
        this.isValid = urlSearch.test(content)

        if (!this.isValid) {
          let status = {
            failed: true,
          }
          return this.dismissModal(status)
        }
        this.$store.dispatch('clearStore')
        const urlArray = urlSearch.exec(content)
        const hotelId = urlArray[2]
        const restaurantId = urlArray[4]
        this.closeModal()
        return this.$router.replace({
          name: 'Tabs',
          params: { hotelId: hotelId, restaurantId: restaurantId },
        })

        // this.turnCameraOn()
      },

      turnCameraOn() {
        this.camera = 'auto'
      },

      turnCameraOff() {
        this.camera = 'off'
      },

      timeout(ms) {
        return new Promise((resolve) => {
          window.setTimeout(resolve, ms)
        })
      },
    },
    computed: {
      validationPending() {
        return this.isValid === undefined && this.camera === 'off'
      },
    },
  }
</script>

<style scoped>
  ion-spinner {
    transform: scale(2);
  }
  .no-ripple {
    --ripple-color: transparent;
  }
  #container {
    width: 100%;
    height: 100%;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .qr-wrapper {
    height: 100%;
  }
  .validation-pending {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 10px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .scanner {
    height: 50%;
  }
</style>
