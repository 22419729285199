<template>
  <ion-page :fullscreen="true">
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title size="large">Tap4Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" @boop="openFailDialog">
      <div class="header-bg">
        <ion-img src="assets/icon/icon.png"></ion-img>
      </div>
      <p>Hi, there &#128512;</p>
      <div class="ion-padding">
        <ion-button expand="block" @click="openModal"
          >Scan QR
          <ion-icon slot="end" :icon="qrCodeOutline"></ion-icon>
        </ion-button>
        <div
          v-if="hotelId && hotelId !== 'undefined' && restaurantId && restaurantId !== 'undefined'"
          class="recent-view"
        >
          <div class="recent-text">
            <span>Recently Viewed</span>
          </div>
          <ion-card>
            <ion-card-content class="ion-padding">
              <ion-label class="title" v-if="storename">{{ storename }}</ion-label>
              <ion-label v-if="storelocation">{{ storelocation }}</ion-label>
              <ion-button @click="openMenu" size="small"
                >Open Menu <ion-icon slot="end" :icon="fastFoodOutline"></ion-icon
              ></ion-button>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    IonImg,
    IonCard,
    IonLabel,
    IonCardContent,
    modalController,
    alertController,
  } from '@ionic/vue'
  import { qrCodeOutline, fastFoodOutline } from 'ionicons/icons'
  import QRreader from '../components/util/QRreader.vue'

  export default {
    name: 'Landing',
    data() {
      return {
        qrCodeOutline,
        fastFoodOutline,
        hotelId: null,
        restaurantId: null,
        storename: '',
        storelocation: '',
      }
    },
    components: {
      IonContent,
      IonHeader,
      IonPage,
      IonTitle,
      IonToolbar,
      IonButton,
      IonIcon,
      IonImg,
      IonCard,
      IonCardContent,
      IonLabel,
    },
    created() {
      if (typeof Storage != undefined) {
        this.hotelId = localStorage.getItem('hotelId')
        this.restaurantId = localStorage.getItem('restaurantId')
        this.storename = localStorage.getItem('storename')
        this.storelocation = localStorage.getItem('storelocation')
      }
    },
    methods: {
      async openModal() {
        const modal = await modalController.create({
          component: QRreader,
          cssClass: 'qr-reader-main',
          backdropDismiss: false,
          componentProps: {
            title: 'tap4menu QR Reader',
          },
        })
        await modal.present()
        let modalResponse = await modal.onDidDismiss()
        if (modalResponse.data) {
          let status = modalResponse.data.failed
          if (status) {
            this.openFailDialog()
          }
        }
      },
      async openFailDialog() {
        const alert = await alertController.create({
          cssClass: 'alert-class-main',
          backdropDismiss: false,
          header: 'Scan Failed',
          message: 'The QR code scanned was invalid',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'Rescan',
              handler: () => {
                this.openModal()
              },
            },
          ],
        })
        return alert.present()
      },
      openMenu() {
        if (this.hotelId !== 'undefined' && this.restaurantId !== 'undefined') {
          return this.$router.replace({
            name: 'Tabs',
            params: { hotelId: this.hotelId, restaurantId: this.restaurantId },
          })
        } else {
          return this.presentAlert()
        }
      },
      async presentAlert() {
        const alert = await alertController.create({
          cssClass: 'my-custom-class',
          header: 'Failed',
          message: 'Failed to open menu please rescan QR code',
          buttons: ['OK'],
        })
        await alert.present()
      },
    },
  }
</script>
<style lang="scss" scoped>
  ion-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    .header-bg {
      position: relative;
      top: 0;
      height: 20%;
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--ion-color-primary);
      border-bottom-left-radius: 50% 20%;
      border-bottom-right-radius: 50% 20%;
      ion-img {
        height: 70%;
        width: 70%;
      }
    }
    .recent-view {
      .recent-text {
        text-align: center;
        padding: 0.5rem 0;
        span {
          font-size: 0.8em;
          font-weight: 400;
          color: var(--ion-color-medium-tint);
        }
      }
      ion-card {
        margin: 0.5rem 0 1rem 0;
        ion-card-content {
          display: flex;
          flex-direction: column;
          .title {
            font-weight: 500;
            font-size: 1.2em;
            color: #000000;
          }
        }
      }
    }
  }
</style>
