import { createRouter, createWebHistory } from '@ionic/vue-router'
import Landing from '../views/Landing.vue'
import Tabs from '../components/navigation/Tabs.vue'

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/home/',
    name: 'Tabs',
    component: Tabs,
    props: true,
    redirect: { name: 'home' },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
      },
      {
        path: 'search',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: { name: 'Landing' } },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from, next) => {
  const urlSearch = /^(\/\?h=)(\d+)+(\+)+(\d+)/
  let isValid = urlSearch.test(to.fullPath)
  if (!isValid) {
    next()
  }
  const urlArray = urlSearch.exec(to.fullPath)
  const hotelId = urlArray[2]
  const restaurantId = urlArray[4]
  next({
    name: 'Tabs',
    params: { hotelId: hotelId, restaurantId: restaurantId },
  })
})

export default router
